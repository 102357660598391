import { DOCTYPE } from "@ems/constants/doc_type";

export const validateDocType = (doctype: string) => {
  switch (doctype) {
    case DOCTYPE[DOCTYPE.CompanyRegistrationDocument]:
      return "Company Registration Document is required";
    case DOCTYPE[DOCTYPE.LetterOfAuthorization]:
      return "Letter Of Authorization is required";
    case DOCTYPE[DOCTYPE.OtherRelevantDocuments]:
      return "Other Relevant Documents is required";
  }
  return null;
};
