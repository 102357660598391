import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import MetadataModule from "@/store/modules/Metadata";
import TenantDataForm from "./TenantData.Form.vue";
import { configure, Field, Form } from "vee-validate";
import { computed, ComputedRef, onMounted, ref, watch } from "vue";
import { mixed, object, string } from "yup";
import { ICountries, IProvinces } from "@/models/Metadata";
import { isEmpty, size } from "lodash";
import { ElMessage } from "element-plus";

import { ITenantRegistration } from "@/models/Tenant";
import { DOCTYPE } from "@ems/constants/doc_type";
import { validateDocType } from "@/utils/helpers/validateDocType";
import { useStore } from "vuex";
import {
  MAX_CHARACTER,
  UPLOAD_FILE_VALIDATION,
  NUMERIC_VALIDATION,
} from "@ems/constants/validation_form";
import TenantUserModule from "../TenantUser.module";
import { OPS_ADMIN } from "@ems/constants";
import { getRole, getTenantIsActive } from "@/utils/storage";
import { SYSTEM } from "@ems/locales/system";
import FileDisplay from "@/components/Common/FileHelper/FileDisplay.vue";
import { IFileMapObject } from "@/models/Company";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantRegistration.Form',
  props: {
  isFormValid: Boolean,
  isDisable: Boolean,
  dataTenantRegistration: Object as () => ITenantRegistration,
},
  emits: ["form-valid"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;
const files = ref<IFileMapObject[]>([]);

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

let initialData = ref<ITenantRegistration>({
  Country: "",
  Province: "",
  CompanyRegistrationNumber: "",
  CompanyName: "",
  Address: "",
  PhoneCode: "",
  ContactNumber: "",
  CompanyRegistrationDocument: null,
  LetterOfAuthorization: null,
  OtherRelevantDocuments: null,
});

// Computed value for prefill data from the store
const prefillData = computed(() => TenantUserModule.prefillDataGetter);

const NUMBER_FORM = {
  MAX_FILES: 1,
};

const allCountries: ComputedRef<ICountries[]> = computed(
  () => MetadataModule.dataCountriesNewGetter
);

const showProvince = ref(false);
const optionsCountry = ref<ICountries[]>([]);
const formValues = ref<ITenantRegistration>(initialData.value);
const optionsProvince = ref<IProvinces[]>([]);
const phoneCode = ref<string[]>([]);
const optionsPhoneCode = ref<string[]>([]);

const schema = object({
  Country: string().required().label("Country"),
  CompanyRegistrationNumber: string()
    .required()
    .label("Company Registration Number")
    .max(20, "Company Registration Number must be at most 20 characters"),
  CompanyName: string()
    .required()
    .label("Company Name")
    .max(150, MAX_CHARACTER.COMPANY_NAME),
  Address: string().required().label("Address").max(255, MAX_CHARACTER.ADDRESS),
  PhoneCode: string().required().label("Phone Code"),
  ContactNumber: string()
    .required()
    .label("Contact Number")
    .max(15, MAX_CHARACTER.CONTACT_NUMBER)
    .matches(/^\d+$/, NUMERIC_VALIDATION.CONTACT_NUMBER),
  CompanyRegistrationDocument: mixed().required().nullable(),
  LetterOfAuthorization: mixed().required().nullable(),

  Province: string().when("$showProvince", {
    is: true,
    then: string().required().label("Province"),
    otherwise: string().nullable(),
  }),
});

const handleGetData = async (values: any) => {
  formValues.value = values;
  let checkValid = false;
  try {
    await schema.validate(formValues.value, {
      abortEarly: false,
      context: { showProvince: showProvince.value },
    });
    checkValid = true;
  } catch (error) {
    checkValid = false;
  }
  emit("form-valid", checkValid, formValues.value);
};

const handleCountryChange = async (
  value: string,
  setFieldValue: any,
  setFieldError: any
) => {
  setFieldValue("Country", value);
  // Logic to determine if Province field should be shown
  await MetadataModule.getAllProvincesByCountryCodeAction(value);

  optionsProvince.value = MetadataModule.dataProvincesByCountryCodeGetter;
  showProvince.value = optionsProvince.value.length > 0;

  const countryEntry = Object.entries(allCountries.value).find(
    ([key, item]) => item.Value === value
  );
  const country: ICountries | undefined = countryEntry
    ? countryEntry[1]
    : undefined;
  phoneCode.value = country?.PhoneCode!.split(",") ?? [];
  optionsPhoneCode.value = country?.PhoneCode!.split(",") ?? [];
  setFieldValue("PhoneCode", phoneCode.value[0]);

  filterMethod("");
};

const handleCountryBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = optionsCountry.value.some((item) => item.Value === value);
  if (!validValue) {
    setFieldValue("Country", "");
  }
};

const handleProvinceBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = optionsProvince.value.some((item) => item.Value === value);
  if (!validValue) {
    setFieldValue("Province", "");
  }
};

const handlePhoneCodeBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = phoneCode.value.some((item) => item === value);
  if (!validValue) {
    setFieldValue("PhoneCode", "");
  }
};

const resetCountryOptions = () => {
  optionsCountry.value = Object.entries(allCountries.value).map(
    ([key, item]) => ({
      Value: item.Value,
      Name: item.Name,
      PhoneCode: item.PhoneCode,
    })
  );
};

const resetProvinceOptions = () => {
  optionsProvince.value = [...MetadataModule.dataProvincesByCountryCodeGetter];
};

const resetPhoneCodeOptions = () => {
  phoneCode.value = [...optionsPhoneCode.value];
};

const filterCountryOptions = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsCountry.value = Object.entries(allCountries.value)
        .filter(([key, item]) => {
          return item.Name.toLowerCase().includes(query.toLowerCase());
        })
        .map(([key, item]) => ({
          Value: item.Value,
          Name: item.Name,
          PhoneCode: item.PhoneCode,
        }));
    }, 200);
  } else {
    resetCountryOptions();
  }
};

const filterProvinceOptions = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsProvince.value =
        MetadataModule.dataProvincesByCountryCodeGetter.filter((item) =>
          item.Name.toLowerCase().includes(query.toLowerCase())
        );
    }, 200);
  } else {
    resetProvinceOptions();
  }
};

const filterMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsCountry.value = Object.entries(allCountries.value)
        .filter(([key, item]) => {
          return item.Name.toLowerCase().includes(query.toLowerCase());
        })
        .map(([key, item]) => ({
          Value: item.Value,
          Name: item.Name,
          PhoneCode: item.PhoneCode,
        }));
    }, 200);
  } else {
    optionsCountry.value = Object.entries(allCountries.value).map(
      ([key, item]) => ({
        Value: item.Value,
        Name: item.Name,
        PhoneCode: item.PhoneCode,
      })
    );
  }
};

const normalizeString = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const filterMethodValueName = (query: string) => {
  if (query) {
    setTimeout(() => {
      const normalizedQuery = normalizeString(query);
      optionsProvince.value =
        MetadataModule.dataProvincesByCountryCodeGetter.filter((item) => {
          return normalizeString(item.Name).includes(normalizedQuery);
        });
    }, 200);
  } else {
    optionsProvince.value = MetadataModule.dataProvincesByCountryCodeGetter;
  }
};

const filterPhoneCode = (query: string) => {
  if (query) {
    setTimeout(() => {
      phoneCode.value = optionsPhoneCode.value.filter((item) => {
        return item.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else {
    phoneCode.value = [...optionsPhoneCode.value];
  }
};

const onChangeFile = (
  name: string,
  showFileList: any,
  setFieldValue: any,
  setFieldError: any
) => {
  // Get Newest File
  const newFile = showFileList[showFileList.length - 1];

  // Check File validation
  const isAllowedType =
    /\.(pdf|doc|docx|jpg|png|jpeg|xls|xlsx|ppt|pptx)$/i.test(newFile.name);
  const isLessThan25MB = newFile.size / 1024 / 1024 < 25;

  if (!isAllowedType || !isLessThan25MB) {
    showFileList.pop();

    const errorMessage = !isAllowedType
      ? UPLOAD_FILE_VALIDATION.TYPE
      : UPLOAD_FILE_VALIDATION.SIZE;

    if (showFileList.length === 0) {
      setFieldError(name, null);
      ElMessage.error(errorMessage);
    } else {
      setFieldError(name, null);
      ElMessage.error(errorMessage);
    }

    setFieldValue(name, showFileList);
    return;
  }

  // Replace File logic
  if (
    showFileList.length > NUMBER_FORM.MAX_FILES &&
    name != DOCTYPE[DOCTYPE.OtherRelevantDocuments]
  ) {
    showFileList.shift();
  } else {
    setFieldError(name, null);
  }
  if (name === DOCTYPE[DOCTYPE.OtherRelevantDocuments]) {
    files.value.push(newFile);
  }
  setFieldValue(name, showFileList);
};

const handleRemoveUploadedFile = (
  name: string,
  showFileList: any,
  setFieldValue: any,
  setFieldError: any
) => {
  if (
    showFileList.length === 0 &&
    name != DOCTYPE[DOCTYPE.OtherRelevantDocuments]
  ) {
    setFieldValue(name, null);
    setFieldError(name, validateDocType(name));
  } else {
    setFieldValue(name, showFileList);
    setFieldError(name, null);
  }
};

const handlePreview = (uploadFile: any) => {
  window.open(URL.createObjectURL(uploadFile.raw));
};

const filterOptionCountry = () => {
  optionsCountry.value = Object.entries(allCountries.value).map(
    ([key, item]) => {
      return {
        Value: `${item.Value}`,
        Name: `${item.Name}`,
        PhoneCode: `${item.PhoneCode}`,
      };
    }
  );
};

const sortedOptions = () => {
  return optionsCountry.value
    .slice()
    .sort((a, b) => a.Name.localeCompare(b.Name));
};

const handleUpdateFile = (
  file: any,
  name: string,
  setFieldValue: any,
  index?: number
) => {
  if (name === DOCTYPE[DOCTYPE.OtherRelevantDocuments]) {
    if (index !== undefined && index >= 0 && index < files.value.length) {
      const updatedFiles = [...files.value];

      updatedFiles[index] = file;

      files.value = updatedFiles;
      setFieldValue(name, files.value);
    }
    return;
  }
  setFieldValue(name, [file]);
};

watch(allCountries, () => {
  filterOptionCountry();
});

watch(
  () => props.dataTenantRegistration,
  async (newVal) => {
    if (newVal && newVal.Country) {
      await MetadataModule.getAllProvincesByCountryCodeAction(newVal.Country);
      optionsProvince.value = MetadataModule.dataProvincesByCountryCodeGetter;
      showProvince.value = optionsProvince.value.length > 0;
    }
  },
  { immediate: true }
);
onMounted(async () => {
  MetadataModule.getAllCountryAction();
  filterOptionCountry();
  var isActive = getTenantIsActive() === "true";
  if (getRole() != OPS_ADMIN && !isActive) {
    await TenantUserModule.getPrefillDataAction();
  }
  if (props.dataTenantRegistration && !isEmpty(props.dataTenantRegistration)) {
    initialData.value = {
      ...initialData.value,
      ...props.dataTenantRegistration,
    };
  } else if (!isEmpty(prefillData.value)) {
    await MetadataModule.getAllProvincesByCountryCodeAction(
      prefillData.value.Country
    );
    optionsProvince.value = MetadataModule.dataProvincesByCountryCodeGetter;
    showProvince.value = optionsProvince.value.length > 0;
    const countryPrefill = optionsCountry.value.find(
      (country) => country.Value === prefillData.value.Country
    );
    phoneCode.value = countryPrefill?.PhoneCode!.split(",") ?? [];
    optionsPhoneCode.value = countryPrefill?.PhoneCode!.split(",") ?? [];
    initialData.value = {
      ...initialData.value,
      ...prefillData.value,
      PhoneCode: phoneCode.value[0],
    };
  }
});

const __returned__ = { props, emit, files, get initialData() { return initialData }, set initialData(v) { initialData = v }, prefillData, NUMBER_FORM, allCountries, showProvince, optionsCountry, formValues, optionsProvince, phoneCode, optionsPhoneCode, schema, handleGetData, handleCountryChange, handleCountryBlur, handleProvinceBlur, handlePhoneCodeBlur, resetCountryOptions, resetProvinceOptions, resetPhoneCodeOptions, filterCountryOptions, filterProvinceOptions, filterMethod, normalizeString, filterMethodValueName, filterPhoneCode, onChangeFile, handleRemoveUploadedFile, handlePreview, filterOptionCountry, sortedOptions, handleUpdateFile, BaseSvgIcon, get MetadataModule() { return MetadataModule }, TenantDataForm, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, ComputedRef, onMounted, ref, watch, get mixed() { return mixed }, get object() { return object }, get string() { return string }, get ICountries() { return ICountries }, get IProvinces() { return IProvinces }, get isEmpty() { return isEmpty }, get size() { return size }, get ElMessage() { return ElMessage }, get ITenantRegistration() { return ITenantRegistration }, get DOCTYPE() { return DOCTYPE }, get validateDocType() { return validateDocType }, get useStore() { return useStore }, get MAX_CHARACTER() { return MAX_CHARACTER }, get UPLOAD_FILE_VALIDATION() { return UPLOAD_FILE_VALIDATION }, get NUMERIC_VALIDATION() { return NUMERIC_VALIDATION }, get TenantUserModule() { return TenantUserModule }, get OPS_ADMIN() { return OPS_ADMIN }, get getRole() { return getRole }, get getTenantIsActive() { return getTenantIsActive }, get SYSTEM() { return SYSTEM }, FileDisplay, get IFileMapObject() { return IFileMapObject } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})