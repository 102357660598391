import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { watch } from "vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'TenantData.Form',
  props: {
  data: Object,
},
  emits: ["form-data"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

watch(props.data!, (newData) => {
  emit("form-data", newData);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})