import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 text-cafe-gray" }
const _hoisted_2 = { class: "text-xs" }
const _hoisted_3 = { class: "shadow-md bg-white px-5 py-5 rounded-lg flex flex-col" }
const _hoisted_4 = { class: "grid grid-cols-8 gap-2" }
const _hoisted_5 = { class: "shadow-md bg-white px-5 py-5 rounded-lg mt-5" }
const _hoisted_6 = { class: "flex gap-10" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-sm font-semibold mt-2 text-center w-40 text-neutral-dark" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "text-sm font-bold mt-2 text-center w-40 text-neutral-dark" }
const _hoisted_11 = { class: "font-semibold text-xl border-t py-5 text-neutral-dark" }
const _hoisted_12 = { class: "flex gap-10" }
const _hoisted_13 = {
  key: 0,
  class: "flex gap-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Form"], {
      as: "el-form",
      "validation-schema": $setup.schema,
      "initial-values": 
        !$setup.isEmpty($props.dataTenantRegistration) ? $props.dataTenantRegistration : $setup.initialData
      ,
      "label-position": "top"
    }, {
      default: _withCtx(({ values, isSubmitting, setFieldValue, setFieldError }) => [
        _createElementVNode("div", {
          class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-bold text-xl mb-4 text-neutral-dark" }, " Company Information ", -1)),
              _createVNode($setup["Field"], { name: "Country" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "w-full label-custom",
                    error: errorMessage,
                    label: "Country",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps(field, {
                        "model-value": value,
                        "value-key": "id",
                        class: "w-full custom-select",
                        placeholder: "Select Country",
                        filterable: "",
                        onInput: (value) => $setup.filterCountryOptions(value.target?.value),
                        onChange: (value) => $setup.handleCountryChange(value, setFieldValue, setFieldError),
                        onBlur: (event) => $setup.handleCountryBlur(event, setFieldValue),
                        onFocus: $setup.resetCountryOptions,
                        disabled: $setup.props.isDisable
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedOptions(), (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value", "onInput", "onChange", "onBlur", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 2
              }, 1024),
              ($setup.showProvince || $props.dataTenantRegistration?.Province)
                ? (_openBlock(), _createBlock($setup["Field"], {
                    key: 0,
                    name: "Province"
                  }, {
                    default: _withCtx(({ value, field, errorMessage }) => [
                      _createVNode(_component_el_form_item, {
                        class: "w-full label-custom",
                        error: errorMessage,
                        label: "Province",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, _mergeProps(field, {
                            "model-value": $props.dataTenantRegistration?.Province ?? value,
                            class: "w-full custom-select",
                            placeholder: "Select Province",
                            filterable: "",
                            onInput: (value) => $setup.filterMethodValueName(value.target?.value),
                            onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.filterProvinceOptions(''))),
                            onBlur: (event) => $setup.handleProvinceBlur(event, setFieldValue),
                            onFocus: $setup.resetProvinceOptions,
                            disabled: $props.isDisable
                          }), {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.optionsProvince, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.Value,
                                  label: item.Name,
                                  value: item.Value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1040, ["model-value", "onInput", "onBlur", "disabled"])
                        ]),
                        _: 2
                      }, 1032, ["error"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              _createVNode($setup["Field"], { name: "CompanyRegistrationNumber" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom",
                    error: errorMessage,
                    label: "Company Registration Number",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({ placeholder: "Enter Company Registration Number" }, field, {
                        "model-value": value,
                        class: "custom-placeholder",
                        disabled: $setup.props.isDisable,
                        required: ""
                      }), null, 16, ["model-value", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode($setup["Field"], { name: "CompanyName" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom",
                    error: errorMessage,
                    label: "Company Name",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({ placeholder: "Enter Company Name" }, field, {
                        "model-value": value,
                        class: "custom-placeholder",
                        disabled: $setup.props.isDisable,
                        required: ""
                      }), null, 16, ["model-value", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode($setup["Field"], { name: "Address" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "label-custom",
                    error: errorMessage,
                    label: "Address",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({ placeholder: "Enter Address" }, field, {
                        "model-value": value,
                        class: "custom-placeholder",
                        disabled: $setup.props.isDisable,
                        required: ""
                      }), null, 16, ["model-value", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                _createVNode($setup["Field"], { name: "PhoneCode" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "label-custom col-span-2",
                      error: errorMessage,
                      label: "Phone Code",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, _mergeProps(field, {
                          "model-value": value,
                          class: "w-full custom-select",
                          placeholder: "Select Phone Code",
                          filterable: "",
                          onInput: (value) => $setup.filterPhoneCode(value.target?.value),
                          onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.filterPhoneCode(''))),
                          onBlur: (event) => $setup.handlePhoneCodeBlur(event, setFieldValue),
                          onFocus: $setup.resetPhoneCodeOptions,
                          disabled: $setup.props.isDisable,
                          required: ""
                        }), {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.phoneCode, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item,
                                label: item,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1040, ["model-value", "onInput", "onBlur", "disabled"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode($setup["Field"], { name: "ContactNumber" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "label-custom col-span-6",
                      error: errorMessage,
                      label: "Contact Number",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "Enter Contact Number" }, field, {
                          "model-value": value,
                          class: "custom-placeholder",
                          disabled: $setup.props.isDisable,
                          required: ""
                        }), null, 16, ["model-value", "disabled"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-bold text-xl mb-4 text-neutral-dark" }, " Required Documents ", -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["Field"], { name: "CompanyRegistrationDocument" }, {
                  default: _withCtx(({ value, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      error: errorMessage,
                      "model-value": value,
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          ($setup.size(value) === 0 || !value)
                            ? (_openBlock(), _createBlock(_component_el_upload, {
                                key: 0,
                                class: "custom-upload",
                                "auto-upload": false,
                                "file-list": value ? value : [],
                                drag: "",
                                multiple: "",
                                limit: 2,
                                "on-change": 
                    (file, showFileList) =>
                      $setup.onChangeFile(
                        'CompanyRegistrationDocument',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                                "on-remove": 
                    (file, showFileList) =>
                      $setup.handleRemoveUploadedFile(
                        'CompanyRegistrationDocument',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                                file: "",
                                disabled: $setup.size(value as any) > 1 || $setup.props.isDisable,
                                "on-preview": $setup.handlePreview,
                                action: "",
                                accept: ".pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx",
                                required: ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode($setup["BaseSvgIcon"], {
                                      class: "w-20 h-20 text-neutral-dark",
                                      name: "UploadFile"
                                    })
                                  ]),
                                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                                ]),
                                _: 2
                              }, 1032, ["file-list", "on-change", "on-remove", "disabled"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode($setup["FileDisplay"], {
                                  file: value[0],
                                  "is-edit": true,
                                  onUpdateFile: 
                            (file) =>
                              $setup.handleUpdateFile(
                                file,
                                'CompanyRegistrationDocument',
                                setFieldValue
                              )
                          
                                }, null, 8, ["file", "onUpdateFile"])
                              ])),
                          _createElementVNode("div", _hoisted_8, _toDisplayString($setup.SYSTEM.company_registration_document), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["error", "model-value"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode($setup["Field"], { name: "LetterOfAuthorization" }, {
                  default: _withCtx(({ value, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      error: errorMessage,
                      "model-value": value,
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          ($setup.size(value) === 0 || !value)
                            ? (_openBlock(), _createBlock(_component_el_upload, {
                                key: 0,
                                class: "custom-upload",
                                "auto-upload": false,
                                "file-list": value ? value : [],
                                drag: "",
                                multiple: "",
                                limit: 2,
                                "on-change": 
                    (file, showFileList) =>
                      $setup.onChangeFile(
                        'LetterOfAuthorization',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                                "on-remove": 
                    (file, showFileList) =>
                      $setup.handleRemoveUploadedFile(
                        'LetterOfAuthorization',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                                file: "",
                                disabled: $setup.size(value as any) > 1 || $setup.props.isDisable,
                                "on-preview": $setup.handlePreview,
                                action: "",
                                accept: ".pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx",
                                required: ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode($setup["BaseSvgIcon"], {
                                      class: "w-20 h-20 text-neutral-dark",
                                      name: "UploadFile"
                                    })
                                  ]),
                                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                                ]),
                                _: 2
                              }, 1032, ["file-list", "on-change", "on-remove", "disabled"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode($setup["FileDisplay"], {
                                  file: value[0],
                                  "is-edit": true,
                                  onUpdateFile: 
                            (fileList) =>
                              $setup.handleUpdateFile(
                                fileList,
                                'LetterOfAuthorization',
                                setFieldValue
                              )
                          
                                }, null, 8, ["file", "onUpdateFile"])
                              ])),
                          _createElementVNode("div", _hoisted_10, _toDisplayString($setup.SYSTEM.letter_of_authorization), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["error", "model-value"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("p", _hoisted_11, _toDisplayString($setup.SYSTEM.other_relevant_documents), 1),
              _createVNode($setup["Field"], { name: "OtherRelevantDocuments" }, {
                default: _withCtx(({ value, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    error: errorMessage,
                    "model-value": value
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        ($setup.files.length > 0)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.files, (file, index) => {
                                return (_openBlock(), _createElementBlock("li", { key: index }, [
                                  _createElementVNode("div", null, [
                                    _createVNode($setup["FileDisplay"], {
                                      file: file,
                                      "is-edit": true,
                                      onUpdateFile: 
                            (fileList) =>
                              $setup.handleUpdateFile(
                                fileList,
                                'OtherRelevantDocuments',
                                setFieldValue,
                                index
                              )
                          
                                    }, null, 8, ["file", "onUpdateFile"])
                                  ])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        ($setup.size(value) < 3)
                          ? (_openBlock(), _createBlock(_component_el_upload, {
                              key: 1,
                              class: _normalizeClass(["custom-upload", [
                    $setup.size(value as any) > 2 ? 'upload-disable' : 'custom-upload-file',
                  ]]),
                              "auto-upload": false,
                              "file-list": $setup.files,
                              drag: "",
                              multiple: "",
                              limit: 3,
                              "on-change": 
                    (file, showFileList) =>
                      $setup.onChangeFile(
                        'OtherRelevantDocuments',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                              "on-remove": 
                    (file, showFileList) =>
                      $setup.handleRemoveUploadedFile(
                        'OtherRelevantDocuments',
                        showFileList,
                        setFieldValue,
                        setFieldError
                      )
                  ,
                              file: "",
                              disabled: $setup.size(value as any) > 3 || $setup.props.isDisable,
                              "on-preview": $setup.handlePreview,
                              accept: ".pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx",
                              "show-file-list": false,
                              action: ""
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createVNode($setup["BaseSvgIcon"], {
                                    class: "w-20 h-20 text-neutral-dark",
                                    name: "UploadFile"
                                  })
                                ]),
                                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "upload_text" }, "Click to upload", -1))
                              ]),
                              _: 2
                            }, 1032, ["class", "file-list", "on-change", "on-remove", "disabled"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["error", "model-value"])
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ], 2),
        _createVNode($setup["TenantDataForm"], {
          data: values,
          onFormData: $setup.handleGetData
        }, null, 8, ["data"])
      ]),
      _: 1
    }, 8, ["validation-schema", "initial-values"])
  ]))
}